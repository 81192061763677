import React, { useEffect } from 'react';
import stickybits from 'stickybits';
import PropTypes from 'prop-types';
import styles from './StickyAdvertisingWrapper.module.scss';

const StickyAdvertisingWrapper = ({ adId }) => {
  const stickySelector = `#${adId}-wrapper`;

  useEffect(() => {
    stickybits(stickySelector, { stickyBitStickyOffset: 90 });
    return () => {
      stickybits(stickySelector).cleanup();
    };
  }, [stickySelector]);

  return (
    <div className={styles.sticky} data-print="none" id={`${adId}-wrapper`}>
      <p>Advertisement</p>
      <div className="amu-sticky-ad" />
    </div>
  );
};

StickyAdvertisingWrapper.propTypes = {
  adId: PropTypes.string.isRequired,
};

export default StickyAdvertisingWrapper;
